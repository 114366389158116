<template>
  <h2 class="card__subtitle">
    {{ value }}
  </h2>
</template>
<script>
import moment from "moment";
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      patient: (state) => state.currentPatient,
      value() {
        if (this.patient) {
          let sex, date;
          this.patient?.sex ? (sex = "ж") : (sex = "ч");
          date = moment(this.patient?.birthdate).format("DD.MM.YYYY");
          return `${this.patient?.fio.first_name} ${this.patient.fio.last_name} ${this.patient.fio.middle_name} (${date}) стать: ${sex}`;
        }
      },
    }),
  },
};
</script>
<style scoped lang="scss">
.card__subtitle {
  text-align: center;
  margin-bottom: 20px;
  font-size: 20px;
  padding: 0 5px;
  @media (max-width: 767px) {
    font-size: 18px;
  }
  @media (max-width: 374px) {
    font-size: 17px;
  }
}
</style>